import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

export default function FrontrunningInEthereum(): JSX.Element {
  return (
    <Layout>
      <SEO title="Blog" />
      <h1>Welcome to my Blog</h1>
      <p>My main interests are Software Development and Blockchain</p>
      <Link to="/blog/frontrunning-in-ethereum">Frontrunning in Ethereum</Link>
    </Layout>
  )
}
